const initialState = {
  data: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

export const BlogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "BLOG_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "BLOG_REQUEST_SUCCESS":
      return {
        data: payload,
        message: {
          success: "blog successfully fetched",
          error: null,
        },
        loading: false,
      };

    case "BLOG_REQUEST_ERROR":
      return {
        data: payload,
        message: {
          error: "An error occurred",
          success: null,
        },
        loading: false,
      };

      case "FETCH_SINGLE_BLOG_REQUEST" : return {
        ...state,
        loading:true,
      }

      case "FETCH_SINGLE_BLOG_SUCCESS" : return {
        data:payload,
        message:{
          success:"single blog fetched successfully",
          error:null
        },
        loading:false,
      }

      case "FETCH_SINGLE_BLOG_ERROR" : return {
        data:payload,
        message:{
          error:"An error occurred while fetching single blog",
          success:null
        },
        loading:false
      }

    default:
      return state;
  }
};
